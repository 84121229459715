import React from 'react';
import logo from '../assets/Unit326.png'
function Header() {
    return (<>

        <header className="bg-black site-header">
            <div className="">
                <div className="site-header-inner">
                    <div className="brand header-brand align-content-lg-start">

                        <ul className="nav ">
                            <li className={'nav justify-content-start'}>
                                <a href={'/'}>
                                    <img  src={logo} width={80} height={80}></img>
                                </a></li>

                            {/*<li className="nav-item p-2 ">*/}
                            {/*    <a className="text-decoration-none text-white hover:text-[#78c8ff]" href="hiking-trails-za">Hiking Trails ZA</a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item p-2">*/}
                            {/*    <a className="pt-10 active text-decoration-none text-white hover:text-[#78c8ff]" target={'_blank'} href="https://www.treplo.studio/"><span className={'text-green-500'}>Treplo</span> Studio</a>*/}
                            {/*</li>*/}

                        </ul>
                    </div>
                </div>
            </div>
        </header>

    </>);
}

export default Header;
