import React from 'react';
import logo from '../../assets/logo.png'

function MedicalPrivacyPolicy() {
    return (<>

        <div className="container p-4">
            <p>


                <h3> Privacy Policy for MediCal </h3>
                <br />
                This Privacy Policy outlines how MediCal ("we", "us", or "our") collects, uses, maintains, and discloses
                information collected from users ("User" or "you") of our Medical Calculator applications ("Apps"). This
                Privacy Policy applies to the Apps and all products and services offered by MediCal.
                <br /><br />
                <h5> Information Collection and Use</h5><p>

                We may collect personal identification information from Users in a variety of ways, including, but not
                limited to, when Users download our Apps, register within the Apps, subscribe to the services offered
                within the Apps, and in connection with other activities, services, features, or resources we make
                available in our Apps. Users may be asked for, as appropriate, name, email address, and other relevant
                information.

                We collect this information only if it is voluntarily provided by the User. Users can always refuse to
                supply personally identification information, except that it may prevent them from engaging in certain
                App-related activities.
            </p>
                <br />
                <h5> Non-personal Information</h5><p>

                We may collect non-personal identification information about Users whenever they interact with our Apps.
                Non-personal identification information may include the type of device used to access the Apps, the
                device's operating system, the unique device identifier, and other technical information about Users'
                means of connection to our Apps, such as the internet service provider utilized and other similar
                information.
            </p>
                <br />
                <h5> How We Use Collected Information</h5><p>

                We collect and use Users' personal information for the following purposes:

                To improve customer service: Information provided helps us respond to customer service requests and
                support needs more efficiently.
                To personalize User experience: We may use information in the aggregate to understand how our Users as a
                group use the services and resources provided in our Apps.
                To send periodic emails: We may use the email address to send User information and updates pertaining to
                their use of the Apps. It may also be used to respond to their inquiries, questions, and/or other
                requests.
                Data Security

                We adopt appropriate data collection, storage, and processing practices and security measures to protect
                against unauthorized access, alteration, disclosure, or destruction of your personal information,
                username, password, transaction information, and data stored in our Apps.
            </p>
                <br />
                <h5> Changes to This Privacy Policy</h5><p>

                We have the discretion to update this Privacy Policy at any time. When we do, we will revise the updated
                date at the bottom of this page. We encourage Users to frequently check this page for any changes to
                stay informed about how we are helping to protect the personal information we collect. You acknowledge
                and agree that it is your responsibility to review this Privacy Policy periodically and become aware of
                modifications.
            </p>
                <br />
                <h5> Your Acceptance of These Terms</h5><p>

                By using our Apps, you signify your acceptance of this Privacy Policy. If you do not agree to this
                Privacy Policy, please do not use our Apps. Your continued use of the Apps following the posting of
                changes to this Privacy Policy will be deemed your acceptance of those changes.
            </p>
                <br />
                <h5> Contacting Us</h5><p>

                If you have any questions about this Privacy Policy, the practices of our Apps, or your dealings with
                our Apps, please contact us at hello@unit326.com.

                This Privacy Policy was last updated on 30 March 2024.</p>
                <br />
            </p>
        </div>

    </>);
}

export default MedicalPrivacyPolicy;
