import React from 'react';
import Footer from "./footer";

import Header from "./header";

import mft from "../assets/mftlogo.png"
import ReactGA from "react-ga4";

import {IoIosArrowForward} from "react-icons/io";
ReactGA.send(window.location.pathname);


export const MFT_FEATURES = [
    {
        id: 1,
        text: 'Vehicle Financing',
        url: 'https://www.myfinance.tools/vehicle-finance',
        description: "What to know how much you'll be paying a month for that new car you want to buy?"
    },
    {
        id: 2,
        text: 'Mortage or Bond',
        url: 'https://www.myfinance.tools/mortgage',
        description: "Find out how much you can expect to pay on your bond or mortgage per month, and how much you can expect to pay in total"
    },
    {
        id: 3,
        text: 'Income Tax ',
        url: 'https://www.myfinance.tools/income-tax',
        description: "Not sure how much tax you're supposed to be paying? find out here"
    },
    {
        id: 4,
        text: 'Retirement ',
        url: 'https://www.myfinance.tools/retirement',
        description: " Find out how much you need to put away a month in order to retire comfortably"
    },
    {
        id: 5,
        text: 'Interest ',
        url: 'https://www.myfinance.tools/interest',
        description: "  Calculate how much interest you will be paying on your next loan"
    },
    {
        id: 6,
        text: 'Amortization',
        url: 'https://www.myfinance.tools/amortization',
        description: "Find out the periodic payments amount due on a loan"
    },
];


function MFT() {

    const [features] = React.useState<any>(MFT_FEATURES);

    return (<>


        <div className=" row">
            <div className="col-md-">

                <Header />


                <div className="p-4 col-md d-flex align-items-stretch ftco-animate">
                    <div className="media block-6 w-100 services d-block text-center">
                        <div className="icon d-flex justify-content-center align-items-center">
                            <img width={150} className={'border rounded'} height={150} src={mft} />
                        </div>
                        <div className="media-body">
                            <h1 className={'text-4xl font-bold tracking-tight lg:text-4xl'}>
                                My Finance Tools
                                <p className={"text-muted text-sm"}> Simple and easy to use financial tools and
                                    calculators</p>
                            </h1>

                            <br />
                        </div>
                    </div>
                </div>

                <div className={'bg-light  p-2 '}>

                    <div className="Heading p-2">
                        <div className="text-center">
                            <span className="badge-clicked text-xs badge align-content-center m-1 badge-pill">Products</span>
                        </div>
                        <h1 className=" text-center p-2">Personal finance made easy</h1>
                    </div>


                    <div className="grid  items-center justify-center  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">


                        {features.map((feature: any) => {

                                return (
                                    <div key={feature.id} className={'rounded-lg shadow m-2 p-4 hover:bg-gray-50 '}>
                                        <a className={'text-decoration-none text-black'} target={'_blank'} href={feature.url}>
                                            <h1 className="scroll-m-20 text-3xl font-extrabold tracking-tight lg:text-2xl">
                                                {feature.text}
                                            </h1>
                                            <div className={'flex'}>
                                                <p className="leading-5 text-xs text-muted-foreground [&:not(:first-child)]:mt-6">
                                                    {feature.description}
                                                </p>
                                                <IoIosArrowForward size={50}/>
                                            </div>
                                        </a>
                                    </div>)
                            }
                        )}


                    </div>


                </div>
            </div>

        </div>
        <Footer />


    </>);
}

export default MFT;
