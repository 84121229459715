import React from 'react';
import logo from '../../assets/logo.png'

function MedicalUsagePolicy() {
    return (<>

        <div className="container p-4">
            <p>


                <h3> Usage Policy for MediCal </h3>
                <br />
                By using MediCal, you agree to the following terms and conditions:
                <br /><br />

                <h5> Accuracy Disclaimer:</h5><p> MediCal is provided for informational purposes only. While we
                strive for accuracy, we cannot guarantee the correctness, completeness, or reliability of the
                calculations provided by the app. Users are advised to verify the results and consult with qualified
                medical professionals before making any medical decisions based on the calculations generated by this
                app.</p>
                <br />
                <h5> No Medical Advice:</h5><p> MediCal is not a substitute for professional medical advice,
                diagnosis, or treatment. The calculations provided by the app are intended to assist healthcare
                professionals in making clinical decisions, but they should not be considered as medical advice. Users
                are solely responsible for any actions taken based on the information provided by this app.</p>
                <br />
                <h5> Limitation of Liability:</h5><p> The owner and creators of MediCal shall not be liable for
                any direct, indirect, incidental, special, or consequential damages arising out of the use or inability
                to use the app, including but not limited to loss of profits, loss of data, or loss of life. Users
                acknowledge that they use MediCal at their own risk.</p>
                <br />
                <h5> No Warranty:</h5><p> MediCal is provided "as is" without any warranty, express or implied.
                The owner and creators of the app disclaim all warranties of merchantability, fitness for a particular
                purpose, and non-infringement. Additionally, the owner and creators do not warrant that the app will be
                error-free or uninterrupted.</p>
                <br />
                <h5> Indemnification:</h5> <p> Users agree to indemnify and hold harmless the owner and creators
                of MediCal from any claims, damages, losses, liabilities, costs, and expenses (including attorney fees)
                arising out of or related to the user's use of the app, violation of these terms and conditions, or
                infringement of any rights of a third party.</p>
                <br />
                <h5> Modifications:</h5> <p> The owner and creators of MediCal reserve the right to modify or
                discontinue the app at any time without prior notice. Users agree that the owner and creators shall not
                be liable for any modification, suspension, or discontinuation of the app.</p>
                <br />
                <h5> Governing Law:</h5><p> These terms and conditions shall be governed by and construed in
                accordance with the laws of International Jurisdiction, without regard to its conflict of law provisions.</p>
                <br /><br />
                By using MediCal, you acknowledge that you have read, understood, and agree to be bound by these terms
                and conditions.
            </p>
        </div>

    </>);
}

export default MedicalUsagePolicy;
