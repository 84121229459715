import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Footer from "./footer";
import Header from "./header";

function Contact() {

    const form = useRef<HTMLFormElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const sendEmail = (e: any) => {
        e.preventDefault();
        setError('')
        setLoading(true)
        // @ts-ignore
        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
            .then((result) => {
                setError("Your request was sent. Please expect a reply from us within 72hours")
                setLoading(false)
            }, (error) => {
                console.log("ds",error)
                setError("Oops, we couldn't send your message there. Please try again")
                setLoading(false)
            });
        setLoading(false)
    };


    return (
        <>
            <Header />

            <section id="contact" className="container p-5 contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>Data History or Data Deletion Request<br/>
                        </h2>
                        Please complete form in order to request your historical data or your delete data from our systems
                    </div>

                    <div className="row">



                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                            <form ref={form} onSubmit={sendEmail} className="php-email-form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Your Name</label>
                                        <input type="text" name="user_name" className="form-control" id="user_name"
                                               required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Your Email</label>
                                        <input type="email" className="form-control" name="user_email" id="user_email"
                                               required />
                                    </div>
                                </div>


                                <div className="form-group col-md-6">
                                    <label htmlFor="company">Request Type</label>
                                    {/*<input type="company" className="form-control" name="company" id="company"*/}
                                    {/*       required />*/}

                                    <select className="form-control" name="company" >
                                        <option value={'Account Deletion'}>Account Deletion</option>
                                        <option value={'Data History Request'}>Data History Request</option>
                                        <option value={'Data Deletion'}>Data Deletion</option>
                                    </select>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="name">Message</label>
                                    <textarea className="form-control" name="message" required></textarea>
                                </div>

                                <div className="text-center p-2">
                                    <button className={'btn btn-dark'} type="submit">{loading ?"Sending...":"Send Request"}</button>
                                </div>

                                {error}
                            </form>
                        </div>

                    </div>

                </div>
            </section>

            <Footer/>

        </>

    );
}

export default Contact;
