import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './assets/flaticon.css'
import 'jquery/dist/jquery.min.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import 'font-awesome/css/font-awesome.min.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from "./components/home";
import HTZA from "./components/htza";
import ReactGA from "react-ga4";
import HTZAPolicy from "./components/htza-policy";
import Contact from "./components/contact";
import TreploStudioPolicy from './components/treplo-studio-policy';
import ToolsPolicy from "./components/326-tools-policy";
import MFT from './components/mft';
import MedicalUsagePolicy from "./components/MediCal/medical-usage-policy";
import MedicalPrivacyPolicy from "./components/MediCal/privacy-policy";
ReactGA.initialize("G-FSMXBEZNVK");
ReactGA.send(window.location.pathname);

const routing = (

        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/treplo-studio-policy' element={<TreploStudioPolicy />} />
                <Route path='/hiking-trails-za' element={<HTZA />} />
                <Route path='/my-finance-tools' element={<MFT />} />
                <Route path='/htza-policy' element={<HTZAPolicy />} />
                <Route path='/data-settings' element={<Contact />} />
                <Route path='/326-tools-policy' element={<ToolsPolicy />} />
                <Route path='/medical-usage-policy' element={<MedicalUsagePolicy />} />
                <Route path='/medical-privacy-policy' element={<MedicalPrivacyPolicy />} />
            </Routes>
        </BrowserRouter>

)


ReactDOM.render([routing], document.getElementById('root'))

