import React from 'react';

function Footer() {
    return (<>


        <footer className="shadow-2xl shadow-orange-400 border-top border-gray-400 bg-black p-10 ">

            <section className="">
                <div className="  text-md-start ">
                    <div className="row ">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

                            <h6 className="text-uppercase text-white fw-bold mb-1">
                            <a className={'text-decoration-none'} href={'/'}> Unit 326.</a>
                            </h6>

                            <p className={'text-white'}>
                                Builders. Creatives.
                            </p>
                        </div>

                        <div className="text-white  col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                            <h6 className="text-uppercase fw-bold mb-1">
                                Socials
                            </h6>
                            <a target="_blank" href="https://twitter.com/Unit_326">
                                <i className="fa fa-twitter me-3 text-white "></i>

                            </a>
                        </div>

                        <div className=" text-white col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-1">

                            <h6 className="text-uppercase fw-bold mb-1">Contact</h6>
                            <p><i className="fa fa-home me-3 text-white "></i> Bryanston, South Africa</p>
                            <p>
                                <i className="fa fa-envelope me-3 text-white "></i>
                                hello@unit326.com
                            </p>
                        </div>
                    </div>

                </div>
            </section>
            <hr className={'text-white'}/>
            <div className="text-center text-white  p-4">
                © 2024 Unit 326 All rights reserved.
            </div>
        </footer>


    </>);
}

export default Footer;
