import React from 'react';
import Footer from "./footer";

import Header from "./header";
import one from "../assets/htza/1.png";
import two from "../assets/htza/2.png";
import three from "../assets/htza/3.png";
import four from "../assets/htza/4.png";
import five from "../assets/htza/5.png";
import six from "../assets/htza/6.png";
import play from "../assets/play.jpeg";
import apple from "../assets/apple.jpg";
import htza from "../assets/htza.png"
import ReactGA from "react-ga4";

ReactGA.send(window.location.pathname);

function HTZA() {
    return (<>


        <div className=" row">
            <div className="col-md-">

                <Header />


                <div className="p-4 col-md d-flex align-items-stretch ">
                    <div className="media block-6 w-100 services d-block text-center">
                        <div className=" d-flex justify-content-center align-items-center">
                            <img width={150} height={150} src={htza} />
                        </div>
                        <div className="media-body">
                            <h1 className={'text-4xl font-bold tracking-tight lg:text-4xl'}>
                                Hiking Trails ZA
                                <p className={"text-muted-foreground text-sm"}> Find hiking trails in South Africa</p>
                            </h1>

                            <div className={'flex align-items-center justify-center p-2'}>

                                <a target={'_blank'}
                                   href={'https://play.google.com/store/apps/details?id=com.unit326.hikingtrailsza'}>
                                    <img
                                        width={150} height={55} src={play} /></a>
                                <a target={'_blank'} href={'https://apps.apple.com/app/id6459467050'}>
                                    <img width={150} height={45} src={apple} />
                                </a>


                            </div>
                            <a className={'text-black text-decoration-none'} href={'/htza-policy'}>HTZA Data And Privacy
                                Policy</a>
                            <br /> <a className={'text-black text-decoration-none'} href={'/data-settings'}>HTZA Data
                            Request</a>

                        </div>
                    </div>
                </div>

                <div className={'bg-light shadow-2xl p-4 '}>

                    <div className="Heading p-4">
                        <div className="text-center">
                            <span className="badge-clicked text-xs badge align-content-center m-1 badge-pill">HTZA</span>
                        </div>
                        <h1 className=" text-center text-4xl font-bold tracking-tight lg:text-4xl">Explore South
                            Africa </h1>
                    </div>
                    <div className="col-md d-flex align-items-stretch ftco-animate">
                        <div className="media block-6 w-100 services  d-block text-center">

                            <div className="icon d-flex justify-content-center align-items-center">
                                <img className="p-4" width={500} height={400} src={one} />
                                <img className="p-2" width={500} height={400} src={two} />
                                <img className="p-4" width={500} height={400} src={three} />


                            </div>
                            <div className="icon d-flex justify-content-center align-items-center pt-5">
                                <img className="p-4" width={500} height={400} src={four} />
                                <img className="p-2" width={500} height={400} src={five} />
                                <img className="p-4" width={500} height={400} src={six} />

                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Footer />


    </>);
}

export default HTZA;
