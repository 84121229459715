import React from 'react';
import Footer from "./footer";

import Header from "./header";
import ReactGA from "react-ga4";

ReactGA.send(window.location.pathname);

function Home() {
    return (<>


        <Header />


        <div className="relative  bg-black pt-10 lg:h-screen h-screen lg:items-center" id="home">
            <div
                 className="absolute  grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
                <div
                    className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
                <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
            </div>


            <div className=" pt-36 ml-auto">
                <div className="lg:w-2/3 text-center mx-auto">
                    <h1
                        className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
                    >
                        User-centric software solutions.


                    </h1>  <p className="mt-8 text-gray-700 dark:text-gray-300">By true builders and creatives </p>


                    <div className={'mt-10  px-10 py-20 mb-10'}>
                        <h1
                            className="text-white bg-clip-text text-4xl pt-10  font-extrabold text-transparent sm:text-3xl"
                        >Products.
                        </h1>

                        <div
                            className="text-center py-4 mt-2 mb-10 pb-10 p-5 dark:border-gray-400 sm:flex justify-between">
                            <a target={'_blank'} href="https://www.treplo.studio/" className={'text-decoration-none'}>
                                <div className="text-left">
                                    <h6 className="text-lg font-semibold text-white">treplo <span
                                        className={'text-sm text-green-900'}>studio</span></h6>
                                    {/*<p className="mt-2 text-gray-500">Some text here</p>*/}
                                </div>
                            </a>
                            <div className="text-left">
                                <a href={'hiking-trails-za'} className={'text-decoration-none'}>
                                    <h6 className="text-lg font-semibold text-gray-700 text-[#297876]">Hiking Trails
                                        ZA</h6>
                                    {/*<p className="mt-2 text-gray-500">Some text here</p>*/}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<section className="bg-black p-10 ">*/}
        {/*    <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">*/}
        {/*       */}
        {/*    </div>*/}
        {/*</section>*/}


        <Footer />


    </>);
}

export default Home;
